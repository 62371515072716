import React from 'react';
import {Autocomplete, Button, styled, TextField} from "@mui/material";
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import {v4 as uuidv4} from 'uuid';
import {
  ammoConfig,
  ammoOptions,
  detonatorOptions,
  ErrorMessages,
  initialTargetValue,
  initiatorOptions,
  objectiveOptions,
  Objectives,
  Results
} from "../const";
import {useFormik} from "formik";
import {addMinutes, format, parse} from "date-fns";
import TargetInput from "./TargetInput";
import {removeAtIndex} from "../utils/arrayUtils";
import {Report} from "../types";

type CreateReportFormValuesType = Pick<Report, 'objective' | 'targets' | 'ammos' | 'detonators' | 'initiators'> & {
  startTime: string;
  endTime: string;
  ammoQuantity: string;
  detonatorQuantity: string;
  initiatorQuantity: string;
};

const initialValues = {
  startTime: '',
  endTime: '',
  objective: objectiveOptions[0],
  targets: [
    {
      value: initialTargetValue,
      description: '',
    }
  ],
  ammos: [],
  ammoQuantity: '',
  detonators: [],
  detonatorQuantity: '',
  initiators: [],
  initiatorQuantity: '',
}

const hhmmRexExp = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

const createReportSchema = yup.object().shape({
  startTime: yup.string().required(ErrorMessages.Required).matches(hhmmRexExp, { message: ErrorMessages.HHmm}),
  endTime: yup.string().required(ErrorMessages.Required).matches(hhmmRexExp, { message: ErrorMessages.HHmm}),
  objective: yup.string().required(ErrorMessages.Required),
  targets: yup.array().of(yup.object({
    value: yup.string().required(ErrorMessages.Required).matches(/^\d{2}[A-Z] [A-Z]{2} \d{5} \d{5}$/, { message: 'Ціль має бути у форматі MGRS' }),
    description: yup.string(),
  })),
  ammos: yup.array().of(yup.string()),
  ammoQuantity: yup.string(),
  detonators: yup.array().of(yup.string()),
  detonatorQuantity: yup.string(),
  initiators: yup.array().of(yup.string()),
  initiatorQuantity: yup.string(),
});

interface CreateReportFormProps {
  onSubmit: (report: Report) => void;
}

const CreateReportForm: React.FC<CreateReportFormProps> = ({ onSubmit }) => {

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik<CreateReportFormValuesType>({
    initialValues,
    validationSchema: createReportSchema,
    onSubmit: (values, { resetForm }) => {
      let startDate, endDate;
      try {
        startDate = parse(values.startTime, 'HH:mm', new Date());
        endDate = parse(values.endTime, 'HH:mm', new Date());
      }catch(e){
      }

      onSubmit({
        ...values,
        id: uuidv4(),
        startTime: startDate || new Date(),
        endTime: endDate || new Date(),
        ammoQuantity: parseInt(values.ammoQuantity) || undefined,
        detonatorQuantity: parseInt(values.detonatorQuantity) || undefined,
        initiatorQuantity: parseInt(values.initiatorQuantity) || undefined,
        result: values.objective === Objectives.Hit ? Results.Hit : Results.Executed,
      });
      resetForm();
    },
  });

  const isAmmoFieldsDisabled = values.objective === Objectives.HumanitarianCargo;

  const handleChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFieldValue('startTime', val);
    try{
      const startDate = parse(val, 'HH:mm', new Date());
      const endDate = addMinutes(startDate, 20);
      setFieldValue('endTime', format(endDate, 'HH:mm'));
    }catch(e){
    }
  }

  const addTarget = () => {
    setFieldValue('targets', [...values.targets, { value: initialTargetValue, description: ''}]);
  }

  const removeTarget = (index: number) => {
    setFieldValue('targets', removeAtIndex(values.targets, index));
  }

  const handleChangeObjective = (value: string) => {
    if(value === Objectives.HumanitarianCargo){
      setFieldValue('detonators', []);
      setFieldValue('detonatorQuantity', '');
      setFieldValue('initiators', []);
      setFieldValue('initiatorQuantity', '');
      setFieldValue('ammos', []);
      setFieldValue('ammoQuantity', '');
    }
    setFieldValue('objective', value);
  };

  const handleChangeAmmo = (value: any) => {
    if(value && value?.length > values.ammos.length){
      const config = ammoConfig[value[value.length -1]]
      if(config){
        !values.initiators.includes(config.initiator) && setFieldValue('initiators', [...values.initiators, config.initiator]);
        !values.detonators.includes(config.detonator) && setFieldValue('detonators', [...values.detonators, config.detonator]);
      }
    }
    setFieldValue('ammos', value || []);
  };

  return <StyledForm onSubmit={handleSubmit}>
    <InputMask
      mask="99:99"
      value={values.startTime}
      onChange={handleChangeStartTime}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete='off'
        label="Час"
        name="startTime"
        error={touched.startTime && Boolean(errors.startTime)}
        helperText={touched.startTime && errors.startTime}
      />}
    </InputMask>
    <InputMask
      mask="99:99"
      value={values.endTime}
      onChange={handleChange}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete='off'
        label="Час закінчення"
        name="endTime"
        error={touched.endTime && Boolean(errors.endTime)}
        helperText={touched.endTime && errors.endTime}
      />}
    </InputMask>
    <Autocomplete
      disableClearable
      value={values.objective}
      onChange={(e, value) => handleChangeObjective(value)}
      options={objectiveOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="objective"
        label="Мета"
        error={touched.objective && Boolean(errors.objective)}
        helperText={touched.objective && errors.objective}
      />}
    />
    {values.targets.map((target, i) => <TargetInput
        key={i}
        target={target.value}
        targetDescription={target.description || ''}
        setFieldValue={setFieldValue}
        index={i}
        isLast={values.targets.length === i + 1}
        addTarget={addTarget}
        removeTarget={() => removeTarget(i)}
        //@ts-ignore
        error={errors.targets?.[0]?.value}
        touched={touched.targets?.[0]?.value}
      />)}

    {!isAmmoFieldsDisabled && <SelectWrapper>
      <StyledAutocomplete
        multiple
        value={values.ammos}
        onChange={(e, value) => handleChangeAmmo(value)}
        options={ammoOptions}
        renderInput={(params) => <StyledTextField
          {...params}
          name="ammos"
          label="Боєприпас"
          error={touched.ammos && Boolean(errors.ammos)}
          helperText={touched.ammos && errors.ammos}
        />}
      />
      <QuantityInput
        label="К-сть"
        name="ammoQuantity"
        value={values.ammoQuantity}
        onChange={handleChange}
        type="number"
        error={touched.ammoQuantity && Boolean(errors.ammoQuantity)}
        helperText={touched.ammoQuantity && errors.ammoQuantity}
      />
    </SelectWrapper>}
    {!isAmmoFieldsDisabled && <SelectWrapper>
      <StyledAutocomplete
        multiple
        value={values.detonators}
        onChange={(e, value) => setFieldValue('detonators', value || [])}
        options={detonatorOptions}
        renderInput={(params) => <StyledTextField
          {...params}
          name="detonators"
          label="Детонатор"
          error={touched.detonators && Boolean(errors.detonators)}
          helperText={touched.detonators && errors.detonators}
        />}
      />
      <QuantityInput
        label="К-сть"
        name="detonatorQuantity"
        value={values.detonatorQuantity}
        onChange={handleChange}
        type="number"
        error={touched.detonatorQuantity && Boolean(errors.detonatorQuantity)}
        helperText={touched.detonatorQuantity && errors.detonatorQuantity}
      />
    </SelectWrapper>}
    {!isAmmoFieldsDisabled && <SelectWrapper>
      <StyledAutocomplete
        multiple
        value={values.initiators}
        onChange={(e, value) => setFieldValue('initiators', value || [])}
        options={initiatorOptions}
        renderInput={(params) => <StyledTextField
          {...params}
          name="initiators"
          label="Ініціатор"
          error={touched.initiators && Boolean(errors.initiators)}
          helperText={touched.initiators && errors.initiators}
        />}
      />
      <QuantityInput
        label="К-сть"
        name="initiatorQuantity"
        value={values.initiatorQuantity}
        onChange={handleChange}
        type="number"
        error={touched.initiatorQuantity && Boolean(errors.initiatorQuantity)}
        helperText={touched.initiatorQuantity && errors.initiatorQuantity}
      />
    </SelectWrapper>}
    <Button variant="contained" type="submit">Додати заявку</Button>
  </StyledForm>
};

export default CreateReportForm;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const SelectWrapper = styled('div')`
  display: flex;
`;
const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
`;
const QuantityInput = styled(StyledTextField)`
  max-width: 65px;
  margin-left: ${({ theme }) => theme.spacing(4)};
`;
