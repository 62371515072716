import {Config} from "../../types";

export const defaultCrewValue = 'Green';
export const crewOptions = ['Філін', 'СС', defaultCrewValue, 'ПОХ'];

export type CrewCompositionItem = string | { value: string, label: string};

type CrewCompositionOptions = {
  [x: string]: CrewCompositionItem[]
}
export const crewCompositionOptions: CrewCompositionOptions = {
  [defaultCrewValue]: ['Грін', 'Ехо', 'Панда', 'Альп'],
  'Філін': ['Філін', 'Дайчі', 'Джо', 'Навєс'],
  'CC': ['CC', 'Атом', 'Кузен', 'Шерп', 'Ронін'],
  'ПОХ': ['Пан Бум', 'Толк', 'Фотограф', 'Купер'],
};

export const configInitialValues: Config = {
  crew: defaultCrewValue,
  crewComposition: [],
  vehicleType: 'Vampire',
  takeOffLocation: 'Мала Токмачка',
  attackArea: 'околиці Малої Токмачки',
  frequencies: '2.4 Vampire',
  altitude: '80-150м',
  areaOfResponsibility: '15 ОГШБ'
}