import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from "./theme";
import {ThemeProvider} from "@mui/material";
import {AppProvider} from "./AppContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import {SnackbarProvider} from "notistack";
import SnackbarCloseButton from "./components/SnackbarCloseButton";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppProvider>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
          <App />
        </SnackbarProvider>
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
