import React, {useContext} from 'react';
import {IconButton, Typography} from "@mui/material";
import AppContext from "../AppContext";
import ConfigForm from "../components/ConfigForm/ConfigForm";
import {ViewType} from "../const";
import Header from "../components/Header";
import Wrapper from "../components/Wrapper";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useSnackbar} from "notistack";

const ConfigView = () => {

  const { config, updateView } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleConfigSubmit = () => {
    updateView(ViewType.Reports);
    enqueueSnackbar('Налаштування збережено', { variant: "success" });
  };

  return <>
    <Header>
      {Boolean(config?.crewComposition.length) && <IconButton
        size="large"
        edge="start"
        color="inherit"
        sx={{ mr: 2 }}
        onClick={() => updateView(ViewType.Reports)}
      >
        <ChevronLeftIcon />
      </IconButton>}
      <Typography variant="h5">Налаштування</Typography>
    </Header>
    <Wrapper>
      <ConfigForm onSubmit={handleConfigSubmit}/>
    </Wrapper>
  </>
};

export default ConfigView;
