import React, {createContext, useEffect, useState} from "react";
import {Config, Report} from "./types";
import {configKey, reportsKey, ViewType} from "./const";
import {configInitialValues} from "./components/ConfigForm/const";

type AppContextType = {
  config: Config,
  updateConfig: (config: Config | null) => void,
  reports: Report[],
  updateReports: (reports: Report[]) => void,
  view: ViewType,
  updateView: (view: ViewType) => void,
}

const initValues = {
  config: configInitialValues,
  updateConfig: () => {},
  reports: [],
  updateReports: () => {},
  view: ViewType.Reports,
  updateView: () => {},
}

const AppContext = createContext<AppContextType>(initValues);

// @ts-ignore
export const AppProvider = ({ children }) => {
  const [config, setConfig] = useState<Config>(configInitialValues);
  const [reports, setReports] = useState<Report[]>([]);
  const [view, setView] = useState<ViewType>(ViewType.Reports);

  const updateConfig = (config: Config | null) => {
    setConfig(prevState => {
      const newConfig = config || {
        ...prevState,
        crew: configInitialValues.crew,
        crewComposition: configInitialValues.crewComposition,
      };
      localStorage.setItem(configKey, JSON.stringify(newConfig));
      return newConfig;
    });
    if(!config) {
      setView(ViewType.Config);
    }
  };

  const updateReports = (reports: Report[]) => {
    setReports(reports);
    localStorage.setItem(reportsKey, JSON.stringify({ reports: reports}));
  }

  const updateView = (view: ViewType) => setView(view);

  //init
  useEffect(() => {
    const serializedConfig = localStorage.getItem(configKey);
    if(serializedConfig){
      try {
        const savedConfig = JSON.parse(serializedConfig);
        if(savedConfig){
          setConfig(savedConfig);
        }
        if(!savedConfig?.crewComposition?.length){
          updateView(ViewType.Config);
        }
      }catch(e){
        localStorage.removeItem(configKey);
      }
    }else{
      updateView(ViewType.Config);
    }

    const reportsSerialized = localStorage.getItem(reportsKey)
    if(reportsSerialized){
      try{
        const reportsObj = JSON.parse(reportsSerialized);
        setReports(reportsObj?.reports?.map((report: Report) => ({
          ...report,
          startTime: new Date(report?.startTime),
          endTime: new Date(report?.endTime),
        })) || []);
      }catch(e){
        localStorage.removeItem(reportsKey);
      }
    }
  }, []);

  return <AppContext.Provider value={{
    config,
    reports,
    updateConfig,
    updateReports,
    view,
    updateView,
  }}>{children}</AppContext.Provider>
};

export default AppContext;