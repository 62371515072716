import {Config, Report, Target} from "../types";
import {format} from "date-fns";

export const formatRequest = (report: Report, config: Config) => {
  const {
    startTime,
    endTime,
    objective,
    targets,
    detonators,
    detonatorQuantity,
    initiators,
    initiatorQuantity,
    ammos,
    ammoQuantity,
  } = report;
  const {
    crew,
    altitude,
    frequencies,
    attackArea,
    takeOffLocation,
    vehicleType,
    areaOfResponsibility,
  } = config;
  return `Екіпаж ${crew} 411 ОБ БпЛА ТРО, який виконує завдання щодо ударної роботи та доставки гуманітарної допомоги (вода, медикаменти, продукти) в смузі відповідальності ${areaOfResponsibility}
Тип засобу: ${vehicleType}
Час: ${format(startTime, 'HH:mm')}
Час закінчення: ${format(endTime, 'HH:mm')}
Взліт: ${takeOffLocation}
Район ураження: ${attackArea}
Частоти: ${frequencies}
Висота: ${altitude}
Мета: ${objective}
${formatTargets(targets)}
Час ураження: ${calcAttackTime(startTime)}
Детонатор: ${joinLabelByComma(detonators)} ${detonatorQuantity && detonatorQuantity > 1 ? `${detonatorQuantity} шт.` : ''}
Ініціатор: ${joinLabelByComma(initiators)} ${initiatorQuantity && initiatorQuantity > 1 ? `${initiatorQuantity} шт.` : ''}
Боєприпас: ${joinLabelByComma(ammos)} ${ammoQuantity && ammoQuantity > 1 ? `${ammoQuantity} шт.` : ''}`
};

export const formatReport = (flightNumber: number, report: Report, config: Config) => {
  const {
    startTime,
    objective,
    targets,
    detonators,
    detonatorQuantity,
    initiators,
    initiatorQuantity,
    ammos,
    ammoQuantity,
    result,
  } = report;

  const {
    frequencies,
    altitude,
    attackArea,
  } = config;
  return `№ вильоту: ${String(flightNumber).padStart(4, '0')}
час, дата: ${format(startTime, 'HH:mm, dd.MM.yyyy')}
Район ураження: ${attackArea}
Частоти: ${frequencies}
Висота: ${altitude}
Мета: ${objective.trim()}
${formatTargets(targets)}
Детонатор: ${joinLabelByComma(detonators)} ${detonatorQuantity && detonatorQuantity > 1 ? `${detonatorQuantity} шт.` : ''}
Плата ініціації: ${joinLabelByComma(initiators)} ${initiatorQuantity && initiatorQuantity > 1 ? `${initiatorQuantity} шт.` : ''}
Боєприпас: ${joinLabelByComma(ammos)} ${ammoQuantity && ammoQuantity > 1 ? `${ammoQuantity} шт.` : ''}
Час ураження: ${calcAttackTime(startTime)}
Результат ${flightNumber}: ${result}`;
};

const formatTargets = (targets: Target[]) => targets.reduce((acc, target, i) => acc + `Ціль${targets.length > 1 ? ' '+(i+1) : ''}: ${target.value}${target?.description ? ', ' + target.description : ''}${i !== targets.length -1 ? '\n' : ''}`, '');

const joinLabelByComma = (arr: string[]) => arr.join(', ') || '-';

const calcAttackTime = (startTime: Date) => format(new Date(startTime.getTime() + 600000), 'HH:mm');