import React from 'react';
import {Button, styled, Typography} from "@mui/material";

interface ConfirmModalBodyProps {
  title: string;
  confirmActionHandler: () => void;
  declineActionHandler: () => void;
}

const ConfirmModalBody: React.FC<ConfirmModalBodyProps> = ({ title, confirmActionHandler, declineActionHandler}) => {
  return <ModalBody>
    <Typography variant="subtitle1">{title}</Typography>
    <ModalActionsWrapper>
      <Button onClick={declineActionHandler} sx={{mr: 2}}>Скасувати</Button>
      <Button onClick={confirmActionHandler} variant="contained">Підтвердити</Button>
    </ModalActionsWrapper>
  </ModalBody>;
};

export default ConfirmModalBody;

const ModalBody = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;
const ModalActionsWrapper = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(4)};
  display: flex;
  justify-content: flex-end;
`;