import React, {useContext} from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import AppContext from "./AppContext";
import {ViewType} from "./const";
import ConfigView from "./views/ConfigView";
import ReportView from "./views/ReportView";

import './styles.css';

function App() {
  const { view } = useContext(AppContext);

  return <>
    <CssBaseline />
    {view === ViewType.Config ? <ConfigView /> : <ReportView />}
  </>;
}

export default App;
