import React, {useContext, useState} from 'react';
import {Report} from "../types";
import AppContext from "../AppContext";
import {formatReport} from "../utils/reportUtils";
import {IconButton, styled, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportEditForm from "./ReportEditForm";
import {removeAtIndex, updateAtIndex} from "../utils/arrayUtils";
import Modal from '@mui/material/Modal';
import ConfirmModalBody from "./ConfirmModalBody";

interface ReportCardProps {
  report: Report;
  reportNumber: number;
}

const ReportCard: React.FC<ReportCardProps> = ({ report, reportNumber }) => {
  const { config, reports, updateReports  } = useContext(AppContext);
  const [isEditing, setIsEditing] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleUpdateReport = (report: Report) => {
    updateReports(updateAtIndex(reports, reportNumber, report));
    setIsEditing(false);
  }

  const handleCloseModal = () => setOpenDeleteModal(false);

  const handleDeleteReport = () => {
    updateReports(removeAtIndex(reports, reportNumber));
    setOpenDeleteModal(false);
  };

  if(isEditing){
    return <ReportEditForm report={report} onSubmit={handleUpdateReport} />
  }

  return <>
    <Wrapper>
      <Typography component="pre">
        {formatReport(reportNumber + 1, report, config)}
      </Typography>
      <ActionsWrapper>
        <IconButton
          size="large"
          color="inherit"
          onClick={() => setIsEditing(true)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          size="large"
          color="inherit"
          onClick={() => setOpenDeleteModal(true)}
        >
          <DeleteIcon />
        </IconButton>
      </ActionsWrapper>
    </Wrapper>
    <Modal
      open={openDeleteModal}
      onClose={handleCloseModal}
    >
      <ConfirmModalBody
        title={`Ви впевнені що хочете видалити виліт №${reportNumber + 1}?`}
        confirmActionHandler={handleDeleteReport}
        declineActionHandler={handleCloseModal}
      />
    </Modal>
  </>

};

export default ReportCard;

const Wrapper = styled('div')`
  width: 100%;
  position: relative;
`;
const ActionsWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
`;
