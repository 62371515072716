import {createTheme} from "@mui/material";

const theme = createTheme({
  spacing: 4,
  palette: {
    mode: 'dark',
    primary: {
      main: '#769CDF', // Primary color
    },
    secondary: {
      main: '#8991A2', // Secondary color
    },
    error: {
      main: '#FF5449', // Error color
    },
    success: {
      main: '#4CAF50', // Green for success messages
    },
    background: {
      default: '#121212', // Dark background color
      paper: '#1E1E1E', // Slightly lighter background for paper components
    },
    text: {
      primary: '#E0E0E0', // Light text color for better contrast
      secondary: '#B0B0B0', // Secondary text color
    },
    grey: {
      50: '#F5F5F5',
      100: '#E0E0E0',
      200: '#B0B0B0',
      300: '#919093', // Medium grey color
      400: '#8E9098', // Darker grey color
      500: '#7D7D7D',
      600: '#6D6D6D',
      700: '#5D5D5D',
      800: '#4D4D4D',
      900: '#3D3D3D',
    },
    action: {
      active: '#A288A6', // Color for active elements
      hover: '#8E9098', // Color for hover effects
      selected: '#4D4D4D', // Color for selected items
      disabled: '#6E6E6E', // Color for disabled items
    },
    divider: '#4A4A4A', // Color for dividers
  },
});

export default theme;
