import React from "react";
import {useSnackbar, SnackbarKey} from "notistack";
import {IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const SnackbarCloseButton: React.FC<{ snackbarKey: SnackbarKey }> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return <IconButton
    aria-label="close"
    color="inherit"
    sx={{ p: 0.5 }}
    onClick={() => closeSnackbar(snackbarKey)}
  >
    <CloseIcon />
  </IconButton>;
};

export default SnackbarCloseButton;