import React from 'react';
import {Report} from "../types";
import {useFormik} from "formik";
import {Autocomplete, Button, styled, TextField} from "@mui/material";
import {format, parse} from "date-fns";
import {
  ammoConfig,
  ammoOptions,
  detonatorOptions,
  initialTargetValue,
  initiatorOptions,
  objectiveOptions, Objectives,
  resultOptions
} from "../const";
import {removeAtIndex} from "../utils/arrayUtils";
import InputMask from "react-input-mask";
import TargetInput from "./TargetInput";

interface ReportEditFormProps {
  report: Report;
  onSubmit: (report: Report) => void;
}
const ReportEditForm: React.FC<ReportEditFormProps> = ({ report, onSubmit }) => {
  const {
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  } = useFormik({
    initialValues: {
      startTime: format(report.startTime, 'HH:mm, dd.MM.yyyy'),
      objective: report.objective,
      targets: report.targets,
      ammos: report.ammos,
      ammoQuantity: report.ammoQuantity,
      detonators: report.detonators,
      detonatorQuantity: report.detonatorQuantity,
      initiators: report.initiators,
      initiatorQuantity: report.initiatorQuantity,
      result: report.result,
    },
    onSubmit: (values) => {
      let startDate;
      try{
        startDate = parse(values.startTime, 'HH:mm, dd.MM.yyyy', new Date());
      }catch (e) {
      }

      onSubmit({
        ...report,
        ...values,
        startTime: startDate || new Date(),
      });
    }
  });

  const isAmmoFieldsDisabled = values.objective === Objectives.HumanitarianCargo;

  const addTarget = () => {
    setFieldValue('targets', [...values.targets, { value: initialTargetValue, description: ''}]);
  }

  const removeTarget = (index: number) => {
    setFieldValue('targets', removeAtIndex(values.targets, index));
  }

  const handleChangeAmmo = (value: any) => {
    if(value && value?.length > values.ammos.length){
      const config = ammoConfig[value[value.length -1]]
      if(config){
        !values.initiators.includes(config.initiator) && setFieldValue('initiators', [...values.initiators, config.initiator]);
        !values.detonators.includes(config.detonator) && setFieldValue('detonators', [...values.detonators, config.detonator]);
      }
    }
    setFieldValue('ammos', value || []);
  };

  const handleChangeObjective = (value: string) => {
    if(value === Objectives.HumanitarianCargo){
      setFieldValue('detonators', []);
      setFieldValue('detonatorQuantity', '');
      setFieldValue('initiators', []);
      setFieldValue('initiatorQuantity', '');
      setFieldValue('ammos', []);
      setFieldValue('ammoQuantity', '');
    }
    setFieldValue('objective', value);
  };

  return <StyledForm onSubmit={handleSubmit}>
    <InputMask
      mask="99:99, 99.99.9999"
      value={values.startTime}
      onChange={handleChange}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete="off"
        label="Час"
        name="startTime"
        error={touched.startTime && Boolean(errors.startTime)}
        helperText={touched.startTime && errors.startTime}
      />}
    </InputMask>
    <Autocomplete
      disableClearable
      value={values.objective}
      onChange={(e, value) => handleChangeObjective(value)}
      options={objectiveOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="objective"
        label="Мета"
        error={touched.objective && Boolean(errors.objective)}
        helperText={touched.objective && errors.objective}
      />}
    />
    {values.targets.map((target, i) => <TargetInput
      key={i}
      target={target.value}
      targetDescription={target.description || ''}
      setFieldValue={setFieldValue}
      index={i}
      isLast={values.targets.length === i + 1}
      addTarget={addTarget}
      removeTarget={() => removeTarget(i)}
    />)}
    {!isAmmoFieldsDisabled && <SelectWrapper>
      <StyledAutocomplete
        multiple
        value={values.ammos}
        onChange={(e, value) => handleChangeAmmo(value)}
        options={ammoOptions}
        renderInput={(params) => <StyledTextField
          {...params}
          name="ammos"
          label="Боєприпас"
          error={touched.ammos && Boolean(errors.ammos)}
          helperText={touched.ammos && errors.ammos}
        />}
      />
      <QuantityInput
        label="К-сть"
        name="ammoQuantity"
        value={values.ammoQuantity}
        onChange={handleChange}
        type="number"
        error={touched.ammoQuantity && Boolean(errors.ammoQuantity)}
        helperText={touched.ammoQuantity && errors.ammoQuantity}
      />
    </SelectWrapper>}
    {!isAmmoFieldsDisabled && <SelectWrapper>
      <StyledAutocomplete
        multiple
        value={values.detonators}
        onChange={(e, value) => setFieldValue('detonators', value || [])}
        options={detonatorOptions}
        renderInput={(params) => <StyledTextField
          {...params}
          name="detonators"
          label="Детонатор"
          error={touched.detonators && Boolean(errors.detonators)}
          helperText={touched.detonators && errors.detonators}
        />}
      />
      <QuantityInput
        label="К-сть"
        name="detonatorQuantity"
        value={values.detonatorQuantity}
        onChange={handleChange}
        type="number"
        error={touched.detonatorQuantity && Boolean(errors.detonatorQuantity)}
        helperText={touched.detonatorQuantity && errors.detonatorQuantity}
      />
    </SelectWrapper>}
    {!isAmmoFieldsDisabled && <SelectWrapper>
      <StyledAutocomplete
        multiple
        value={values.initiators}
        onChange={(e, value) => setFieldValue('initiators', value || [])}
        options={initiatorOptions}
        renderInput={(params) => <StyledTextField
          {...params}
          name="initiators"
          label="Ініціатор"
          error={touched.initiators && Boolean(errors.initiators)}
          helperText={touched.initiators && errors.initiators}
        />}
      />
      <QuantityInput
        label="К-сть"
        name="initiatorQuantity"
        value={values.initiatorQuantity}
        onChange={handleChange}
        type="number"
        error={touched.initiatorQuantity && Boolean(errors.initiatorQuantity)}
        helperText={touched.initiatorQuantity && errors.initiatorQuantity}
      />
    </SelectWrapper>}
    <Autocomplete
      disableClearable
      value={values.result}
      onChange={(e, value) => setFieldValue('result', value)}
      options={resultOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="result"
        label="Результат"
        error={touched.result && Boolean(errors.result)}
        helperText={touched.result && errors.result}
      />}
    />
    <Button variant="contained" type="submit">Зберегти</Button>
  </StyledForm>
};

export default ReportEditForm;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const SelectWrapper = styled('div')`
  display: flex;
`;
const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
`;
const QuantityInput = styled(StyledTextField)`
  max-width: 65px;
  margin-left: ${({ theme }) => theme.spacing(4)};
`;