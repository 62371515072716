import React, {ReactNode} from 'react';
import {AppBar, Toolbar} from "@mui/material";

interface HeaderProps {
  children: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  return <AppBar position="sticky">
    <Toolbar>
      {children}
    </Toolbar>
  </AppBar>
};

export default Header;